import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { ComponentProps, PropsWithChildren, useMemo } from 'react'
import { DoubleTriangleSvg } from './DoubleTriangle'
import {
  StyledButton,
  StyledSubText,
  StyledTitle,
  StyledToolCtaAction,
  StyledToolCtaContainer,
  StyledToolCtaContent,
  StyledTrapezoidBackground,
  StyledTrapezoidChild,
  StyledTriangleBox,
} from './StyledToolCta'

type ToolCtaWrapperProps = ComponentProps<typeof StyledToolCtaContainer> & {
  //
}

type ToolCtaTitleProps = ComponentProps<typeof StyledTitle> & {
  //
}

type ToolCtaSubTitleProps = ComponentProps<typeof StyledSubText> & {
  //
}

type ToolCtaButtonProps = ComponentProps<typeof StyledButton> & {
  href: string
}

type ToolCtaItemProps = {
  //
}

export type ToolCtaProps = {item?: boolean; title?: boolean; subText?: boolean; cta?: boolean } &  (
  | ({ item: true } & ToolCtaItemProps)
  | ({ title: true } & ToolCtaTitleProps)
  | ({ subText: true } & ToolCtaSubTitleProps)
  | ({ cta: true } & ToolCtaButtonProps)
  | ToolCtaWrapperProps)

export const ToolCta: React.FC<PropsWithChildren<ToolCtaProps>> = ({ item, title, subText, cta, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dimension = useMemo(() => {
    if (isMobile) {
      return { w: 110, h: 180 }
    }

    return { w: 142, h: 180 }
  }, [isMobile])

  if (item) {
    return (
      <ToolCtaElements layout={isMobile ? 'vertical' : 'horizontal'}>
        {props.children}
        <React.Fragment></React.Fragment>
      </ToolCtaElements>
    )
  }

  if (title) {
    return (
      <StyledTitle variant="h4" {...props}>
        {props.children}  
      </StyledTitle>
    )
  }

  if (subText) {
    return (
      <StyledSubText variant="body1" {...props}>
        {props.children}
      </StyledSubText>
    )
  }

  if (cta) {
    return (<StyledButton primary buttonColor="orange" { ...props } >{props.children}</StyledButton>)
  }

  return (
    <StyledToolCtaContainer {...props}>
      <StyledTrapezoidBackground>
        <StyledTrapezoidChild>
          {props.children}
        </StyledTrapezoidChild>
      </StyledTrapezoidBackground>
      <StyledTriangleBox>
        <DoubleTriangleSvg width={dimension.w} height={dimension.h}></DoubleTriangleSvg>
      </StyledTriangleBox>
    </StyledToolCtaContainer>
  )
}

export type ToolCtaElementsProps = {
  children: Array<React.ReactNode>
  layout: 'vertical' | 'horizontal'
}

export const ToolCtaElements: React.FC<PropsWithChildren<ToolCtaElementsProps>> = ({ children, layout, ...props }) => {
  if (!children) {
    return null
  }
  
  const [title, subTitle, button] = React.Children.toArray(children)

  if (layout === 'horizontal') {
    return (
      <StyledToolCtaContent {...props} tabIndex={0}>
        <StyledToolCtaAction>
          {
            title
          }
          {
            button
          }
        </StyledToolCtaAction>
        {
          subTitle
        }
      </StyledToolCtaContent>
    )
  }

  return (
    <StyledToolCtaContent {...props} tabIndex={0}>
      {children}
    </StyledToolCtaContent>
  )
}