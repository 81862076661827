import React, { createContext } from 'react'

export type DisclaimerType = {
  disclaimerId: string
  disclaimerText: string
}

export type DisclaimerIconMap = Map<string, React.ReactNode>


type DisclaimerContextType = {
  pageId: string
  disclaimerArray: DisclaimerType[]
  iconMap: DisclaimerIconMap
}

export const DisclaimerContext = createContext<DisclaimerContextType>({
  disclaimerArray: [],
  iconMap: new Map(),
  pageId: '',
})