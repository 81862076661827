import { Container, ContainerProps, Grid } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

export type TabWrapperProps = Omit<ContainerProps, 'maxWidth' | 'disableGutters'> & {
    //
}

export const TabWrapper: React.FC<PropsWithChildren<TabWrapperProps>> = ({ children, ...props }) => {
  return (
    <Container maxWidth="lg" {...props}>
      <StyledGrid container direction="column" justifyContent="flex-start" alignItems="center">
        {children}
      </StyledGrid>
    </Container>
  )
}

export const StyledGrid = styled(Grid)`
  width: 100%;
`
