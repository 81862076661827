import { Tab, TabProps } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

export type TabComponentProps = TabProps

const StyledTab = styled(Tab)`
  max-width: none;
  white-space: nowrap;
  padding: 0;
  text-transform: inherit;
  font-weight: 200;
  font-size: ${props => props.theme.typography.pxToRem(18)};
  margin-right: ${props => props.theme.typography.pxToRem(22)};
  min-width: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
    margin-right: ${props => props.theme.typography.pxToRem(30)};
    margin-bottom: ${props => props.theme.typography.pxToRem(6)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-right: ${props => props.theme.typography.pxToRem(69)};
  }

  &.Mui-selected {
    font-weight: ${props => props.theme.typography.fontWeightMedium};
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const HeaderTabs: React.FC<PropsWithChildren<TabComponentProps>> = ({ ...props }) => {
  return (
    <StyledTab { ...props} />
  )
}

