import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { irem } from '../../utils'

export type TabPanelProps = {
    index: number
    value: number
}

export const StyledContentGrid = styled(Grid)`
  padding-top: ${irem(48)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${irem(90)};

    div.MuiGrid-item {
      > * {
        margin-top: 0;
      }
    }
  }

  > * {
    animation: fadeIn 1.5s;

    ${props => props.theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding-left: 0;
      padding-right: 0;
    }

    ${props => props.theme.breakpoints.up('md')} {
      gap: ${irem(64)};
    }

    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }

  div.MuiGrid-item {
    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ index, value, children, ...props }) => {
  return(
    <>
      {
        index === value ? (
          <StyledContentGrid container {...props}>
            { children}
          </StyledContentGrid> 
        ) : null
      }
    </> 
  )
}