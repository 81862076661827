import { Tabs, TabsProps } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { irem } from '../../utils'

export const StyledTabContainer = styled(Tabs)`
  flex: 1;
  padding-bottom: 0;
  margin-top: ${irem(-20)};
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    height: ${props => props.theme.typography.pxToRem(3)};
    width: 100%;
    position: absolute;
    bottom: 0;
    background: ${props => alpha(props.theme.palette.text.secondary, 0.19)};
  }

  .MuiTabScrollButton-root {
    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: ${props => props.theme.typography.pxToRem(6)};
    }

    &.Mui-disabled {
      display: none;
    }

    > svg {
      color: ${props => props.theme.palette.text.secondary};
      font-size: ${props => props.theme.typography.pxToRem(28)};
      transition: transform 0.2s ease;
    }

    &:hover {
      > svg {
        color: ${props => props.theme.palette.primary.main};
        transform: translateX(${props => props.theme.typography.pxToRem(5)});
      }
    }
  }

  .MuiTabs-indicator {
    height: ${props => props.theme.typography.pxToRem(3)};
    background-color: ${props => props.theme.palette.primary.main};
    z-index: 5;
    width: calc(100% - ${props => props.theme.typography.pxToRem(22)});
  }

  .MuiTabs-scrollable[style*="top: -9999px"] {
    display: none;
  }
`

export type TabsPropsFixed = TabsProps

export const TabHeader: React.FC<PropsWithChildren<TabsPropsFixed>> = ({ children, ...props }) => {
  return(
    <StyledTabContainer scrollButtons="auto" { ...props}>
      { children }
    </StyledTabContainer>
  )
}
