import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { Button } from '../button/button'
import { irem } from '../../utils'

export const StyledToolCtaContainer = styled.div`
  width: 100%;
  max-width: calc(100% - ${irem(18)});
  position: relative;

  ${props => props.theme.breakpoints.up('md')} {
    margin: auto;
    max-width: ${props => props.theme.typography.pxToRem(1200)};
  }
`

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(17)};
  font-weight: 500;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    line-height: ${props => props.theme.typography.pxToRem(30)};
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const StyledSubText = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const StyledButton = styled(Button)`
  white-space: nowrap;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  flex: 0 0 auto;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const StyledToolCtaContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.typography.pxToRem(16)};
  justify-content: center;
  align-items: flex-start;

  &:focus {
    outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
  }

  a {
    &:focus-within,
    &:focus {
      outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    gap: ${props => props.theme.typography.pxToRem(39)};
  }
`

export const StyledTrapezoidBackground = styled.div`
  width: 100%;
  filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => alpha(props.theme.palette.text.secondary, 0.19)});
  position: relative;
  padding: 0;
  z-index: 1;
  background: transparent;
  -webkit-transform: translate3d(0, 0, 0);
`

export const StyledTrapezoidChild = styled.div`
  width: 100%;
  z-index: 10;
  clip-path: polygon(90% 0, 100% ${props => props.theme.typography.pxToRem(118)}, 100% 100%, 0 100%, 0 0);
  background: ${props => props.theme.palette.common.white};
  padding: ${props => props.theme.typography.pxToRem(25)} ${props => props.theme.typography.pxToRem(38)};
  display: flex;
  flex-direction: column;

  > ${StyledToolCtaContent}:not(:first-child) {
    padding-top: ${props => props.theme.typography.pxToRem(33)};
    padding-bottom: ${props => props.theme.typography.pxToRem(12)};
  }

  > ${StyledToolCtaContent}:not(:last-child) {
    padding-top: ${props => props.theme.typography.pxToRem(12)};
    padding-bottom: ${props => props.theme.typography.pxToRem(40)};
    border-bottom: solid ${props => props.theme.typography.pxToRem(1)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
  }
  ${props => props.theme.breakpoints.up('sm')} {
    clip-path: polygon(94% 0, 100% ${props => props.theme.typography.pxToRem(168)}, 100% 100%, 0 100%, 0 0);
    padding-right: ${props => props.theme.typography.pxToRem(63)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    width: calc(100% - ${props => props.theme.typography.pxToRem(40)});
    clip-path: polygon(0 0, 94% 0%, 100% 100%, 0% 100%);
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: ${props => props.theme.typography.pxToRem(118)};
    height: ${props => props.theme.typography.pxToRem(296)};
    padding: ${props => props.theme.typography.pxToRem(78)} ${props => props.theme.typography.pxToRem(138)} ${props => props.theme.typography.pxToRem(78)} ${props => props.theme.typography.pxToRem(59)};

    > ${StyledToolCtaContent} {
      width: 50%;
    }

    > ${StyledToolCtaContent}:not(:first-child) {
      padding-top: 0;
      padding-bottom: 0;
    }

    > ${StyledToolCtaContent}:not(:last-child) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: ${props => props.theme.typography.pxToRem(2)};
        min-height: ${props => props.theme.typography.pxToRem(145)};
        height: 100%;
        top: 0;
        background: ${props => alpha(props.theme.palette.text.secondary, 0.19)};

        ${props => props.theme.breakpoints.up('sm')} {
          right: ${props => props.theme.typography.pxToRem(-59)};
        }
      }
    }
  }
`

export const StyledTriangleBox = styled.div`
  position: absolute;
  top: ${props => props.theme.typography.pxToRem(-15)};
  right: ${props => props.theme.typography.pxToRem(5)};
  transform: translateX(22%);

  ${props => props.theme.breakpoints.up('sm')} {
    position: absolute;
    transform: translateX(17%);
    top: ${props => props.theme.typography.pxToRem(4)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    top: unset;
    right: 0;
    bottom: ${props => props.theme.typography.pxToRem(-9)};
    transform: translateX(3%);

    svg {
      width: 100%;
    }
  }
`

export const StyledToolCtaAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    gap: ${props => props.theme.typography.pxToRem(30)};
  }
`